@media screen and (min-width: 1800px) {
  .app{
    width: 1800px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1600px) {
  footer .container{
    padding-right: 150px;
  }
}

@media screen and (max-width: 1300px) {
  body{
    font-size: 18px;
    h2 {
      font-size: 80px;
    }
    h3 {
      font-size: 40px;
    }
    h4 {
      font-size: 26px;
    }
    .list-vacancy .vacancy .name{
      font-size: 20px;
    }
    .box-4 .title-slider{
      padding-left: 50px;
    }
    .about-slider .text-slider {
      padding-top: 60px;
    }
    .box-4 .btn-slider {
      bottom: 60px;
      margin-left: -40px;
    }
    .box-4 .slick-slider:before{
      top: 40px;
    }
    .header-slider h1{
      font-size: 100px;
      left: 50px;
    }
    .box-3 ul {
      padding-right: 20px;
    }
    .container {
      padding: 0 25px;
    }
    footer{
      .container{
        padding: 80px 150px 80px 25px;
      }
    }
  }
  header .container{
    padding: 30px 25px;
  }
}

@media screen and (max-width: 1280px) {
  .slider-text h1{
    font-size: 128px;
  }
}

@media screen and (max-width: 1150px) {
  .slider-text h1{
    font-size: 110px;
  }
  .about-slider .slick-slide .item-slider .row{
    min-height: 440px;
  }
}

@media screen and (max-width: 768px) {
  header{
    .btn.btn-fixed{
      display: inline-block;
      width: 80%;
      left: 10%;
      position: fixed;
      bottom: 30px;
      z-index: 100;
      &.active{
        display: none;
      }
    }

    .btn-fixed-container.active .btn.btn-fixed{
      display: none;
    }
  }

  .locked header .btn.btn-fixed {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  body{
    font-size: 14px;
    .container {
      padding: 0 20px;
    }
    h2{
      font-size: 40px;
      line-height: 0.83;
    }
    h3 {
      font-size: 30px;
    }
    h4 {
      font-size: 24px;
    }
  }
  p {
    line-height: 1.7;
  }
  header{
    padding: 24px 0;
    position: static;
    .logo {
      height: 43px;
    }
    .nav,.btn{
      display: none;
    }
    .container{
      padding: 0 25px;
    }
  }
  .menu{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    transition: z-index 0.01s ease-in-out 0.2s;
    -webkit-transition: z-index 0.01s ease-in-out 0.2s;
    .menu-content{
      opacity: 0;
      z-index: 10;
      position: relative;
      height: 100%;
      padding: 100px 25px 35px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .nav{
        display: block;
        li{
          display: block;
          font-size: 18px;
          font-weight: bold;
          margin: 0 0 35px;
          min-width: auto;
          min-height: auto;
          a{
            text-shadow: none;
            color: inherit;
            position: static;
          }
          &:hover{
            color: #ff4136;
          }
        }
      }
      .box-social{
        margin: 0;
      }
      .box-logo{
        display: flex;
        justify-content: space-between;
        img{
          height: 13px;
        }
      }
      .phone,.email{
        display: block;
        font-size: 18px;
        line-height: normal;
      }
    }
    .background {
      position: fixed;
      display: flex;
      flex-flow: column;
      align-items: stretch;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .portion {
        transition: transform 0.2s ease-in-out;
        -webkit-transition: transform 0.2s ease-in-out;
        flex: 1 1 100%;
        background: #f6f4f4;
        &:nth-child(odd) {
          transform: translateX(-100%);
        }
        &:nth-child(even) {
          transform: translateX(100%);
        }
        &:not(:first-child) {
          margin-top: -1px;
        }
      }
    }
    &.menu-open{
      transition: z-index 0.01s ease-in-out 0.3s;
      -webkit-transition: z-index 0.01s ease-in-out 0.3s;
      z-index: 10;
      opacity: 1;
      .menu-content{
        opacity: 1;
        transition: opacity 0.3s ease-in-out 0.4s;
        -webkit-transition: opacity 0.3s ease-in-out 0.4s;
      }
      .background{
        .portion {
          transform: translateX(0%);
          transition: transform 0.2s ease-in-out 0.3s;
          -webkit-transition: transform 0.2s ease-in-out 0.3s;
        }
      }
    }
  }
  .header-slider .slick-list{
    width: 100%;
  }

  $color: #dd4339;

  $height-icon: 24px;
  $width-line: 31px;
  $height-line: 4px;

  $transition-time: 0.4s;
  $rotation: 45deg;
  $translateX: 0;

  .hamburger-icon {
    width: $width-line;
    height: $height-icon;
    position: absolute;
    display: block;
    right: 20px;
    background: transparent;
    border: 0;
    cursor: pointer;
    z-index: 100;

    .line {
      display:block;
      background:$color;
      width:$width-line;
      height:$height-line;
      position:absolute;
      left:0;
      border-radius:($height-line / 2);
      transition: all $transition-time;

      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
        margin-top: -2px;
      }
      &.line-3 {
        bottom: 0;
      }
    }
    &.active {
      .line-1 {
        transform: translateY(9px) translateX($translateX) rotate($rotation);
        -webkit-transform: translateY(9px) translateX($translateX) rotate($rotation);
      }
      .line-2 {
        opacity:0;
      }
      .line-3 {
        transform: translateY(-11px) translateX($translateX) rotate($rotation * -1);
        -webkit-transform: translateY(-11px) translateX($translateX) rotate($rotation * -1);
      }
    }
  }
  .slider-text h1{
    font-size: 38.5px;
    font-weight: 800;
    top: 48px;
  }
  .header-slider{
    &:before{
      content: none;
    }
    .slick-dots{
      text-align: center;
      bottom: 45px;
      li{
        button{
          width: 22px;
          height: 3px;
          &:after{
            width: 22px;
            height: 3px;
          }
        }
      }
    }
  }
  .header-slider .slick-slide img{
    min-height: 200px;
  }
  .program{
    position: static;
    margin: 0;
    b{
      font-size: 16px;
      line-height: 1.25;
    }
    .col-6.bg{
      display: none;
    }
    .col-6{
      padding: 30px 94px 30px 0;
      min-height: 120px;
      &:first-child:before{
        background-size: 76px 73px;
        right: 20px;
        left: initial;
        width: 76px;
        height: 73px;
      }
    }
  }
  .box-1{
    height: auto;
    margin-top: -34px;
    .container{
      background-color: #356c8c;
    }
  }
  .row{
    margin: 0;
  }
  [class^=col-] {
    padding: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .box-2 {
    padding: 40px 0 70px;
  }
  .box-3{
    h2 {
      margin-bottom: 30px;
    }
    h4{
      margin-bottom: 0;
    }
    p{
      font-size: 16px;
      line-height: 1.5;
    }
    .col-4{
      margin-bottom: 35px;
      &.bg:before{
        display: none;
      }
    }
    li{
      margin-bottom: 30px;
      line-height: 24px;
      &:before{
        top: 1px;
      }
      span{
        margin-left: 13px;
      }
    }
    padding-bottom: 0;
    margin-bottom: 70px;
  }
  .row-list {
    margin-top: 45px;
    .col-4{
      margin-bottom: 0;
    }
  }
  .mobile{
    display: block;
  }
  .box-4{
    margin: 0;
    h2.mobile{
      margin-bottom: 25px;
    }
    .slick-slider{
      &:before{
        content: none;
      }
    }
  }
  .about-slider{
    background: #f6f4f4;
    .slick-slide{
      img{
        padding-right: 0;
        max-width: 100%;
        width: 100%;
      }
      .item-slider{
        .text-slider{
          padding: 55px 20px 50px;
          background-color: #f7f4f4;
          max-width: 100%;
          h3{
            margin-bottom: 30px;
          }
        }
        h3:after{
          content: none;
        }
      }
    }
    .slick-dots{
      text-align: center;
      bottom: 50px;
      margin: 0 auto;
      width: 100%;
      position: relative;
      background-color: #f6f4f4;
      padding: 10px;
      li{
        width: auto;
        height: auto;
        &.slick-active{
          opacity: 1;
          span{
            &:hover{
              opacity: 1;
            }
          }
        }
        span{
          width: 22px;
          height: 3px;
          padding: 10px 4px;
          &:hover{
            opacity: .3;
          }
          &:after {
            content: '';
            display: inline-block;
            width: 22px;
            height: 3px;
            border-radius: 2px;
            background-color: #4b6b8a;
          }
          img{
            display: none;
          }
        }
        &.slick-active{
          span:after{
            background-color: #ff4136;
          }
        }
      }
    }
  }
  .title-slider{
    display: none;
  }
  .box-5 {
    padding: 50px 0;
    .list-vacancy{
      padding-top: 30px;
      &.row{
        margin: 0;
        [class^=col-]{
          padding: 0;
          margin-bottom: 4px;
        }
        .col-3{
          &:nth-child(odd){
            .vacancy {
              background: #faf9f9;
            }
          }
          &:nth-child(even){
            .vacancy {
              background: #f2fcfc;
            }
          }
        }
      }
      .vacancy{
        display: flex;
        justify-content: space-between;
        padding: 10px 14px;
        .name {
          font-size: 13px;
          min-height: auto;
        }
        .sum{
          font-size: 12px;
          min-height: auto;
          white-space: nowrap;
        }
      }
    }
  }
  .box-6{
    background-color: #f6f4f4;
    padding: 45px 0 55px;
    .col-6{
      padding: 0;
      &.bg:before,&:before{
        content: none;
      }
    }
    h3{
      font-size: 24px;
    }
    label {
      font-size: 16px;
    }
    textarea,input{
      font-size: 14px;
      background-color: #fff;
      max-width: 100%;
    }
    .form-check label{
      font-size: 12px;
      &:before{
        background-color: #fff;
      }
    }
  }
  .btn{
    width: 100%;
  }
  .box-7{
    padding-top: 70px;
    h3{
      font-size: 24px;
    }
    .contact{
      padding: 25px 0 0;
      .phone, .email{
        font-size: 18px;
        line-height: normal;
      }
    }
  }
  .box-social{
    margin: 44px 0 25px;
    .social{
      width: 46px;
      height: 46px;
      background-size: 46px;
    }
  }
  footer{
    background-color: #f7f4f4;
    &:before{
      content: '';
    }
    .pdf{
      display: block;
      position: absolute;
      margin-top: 32px;
      font-size: 13px;
    }
  }
  .form-group,.form-check{
    .field-error {
      font-size: 12px;
    }
  }
  .app{
    footer{
      .container{
        padding: 38px 20px 65px;
      }
    }
  }
  .ancor,.ru-port{
    height: 18px;
  }
  .ReactModal__Content{
    width: 100%!important;
    top: 0!important;
    margin: 0!important;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .vacancy-modal{
      padding: 50px 20px 60px;
      margin: 0;
      h3{
        font-size: 24px;
      }
      .vacancy-experience{
        margin-top: 30px;
      }
      .vacancy-description,.vacancy-responsibility,.responsibility-title{
        margin: 20px 0 40px;
      }
      textarea{
        font-size: 14px;
      }
      .form-check label{
        font-size: 12px;
      }
      &:after{
        content: none;
      }
    }
    .vacancy-modal-close-btn{
      left: 100%;
      margin-left: -45px;
      top: -15px;
      position: relative;
      width: 44px;
      height: 44px;
    }
  }
  .submit-modal p{
    font-size: 14px;
  }
  .locked{
    height: 50%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .slider-text h1{
    font-size: 80px;
  }
  .program .col-6 {
    padding: 50px 94px 50px 0;
  }
  .program b {
    font-size: 20px;
  }
  body {
    font-size: 16px;
  }
  .box-5 .list-vacancy .vacancy{
    .name{
      font-size: 16px;
    }
    .sum {
      font-size: 14px;
    }
  }
  .about-slider .slick-slide .item-slider .text-slider{
    min-height: 260px;
  }
  .box-6 .form-check label,.ReactModal__Content .vacancy-modal .form-check label {
    font-size: 14px;
  }
  .submit-modal p{
    font-size: 16px;
  }
}
